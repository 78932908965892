export const generateOrganizationSchema = () => {
  return {
    "@context": "https://schema.org",
    "@type": "CollegeOrUniversity",
    "@id": "https://www.unicollege.co.za/#organization",
    "name": "UniCollege",
    "url": "https://www.unicollege.co.za",
    "logo": {
      "@type": "ImageObject",
      "url": "https://www.unicollege.co.za/images/logos/unicollege-logo-white-cropped.svg",
      "width": "112",
      "height": "112"
    },
    "sameAs": [
      "https://www.facebook.com/@unicollege"
    ],
    "address": {
      "@type": "PostalAddress",
      "addressCountry": "ZA",
      "addressLocality": "South Africa",
      "streetAddress": "17 Clew Street, Monument, West Rand, Gauteng",
      "postalCode": "1739"
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "011 955 5326",
      "contactType": "customer service",
      "email": "info@unicollege.co.za",
      "availableLanguage": ["English", "Afrikaans"]
    },
    "areaServed": {
      "@type": "Country",
      "name": "South Africa"
    },
    "foundingDate": "2013",
    "description": "UniCollege is a leading educational institution in South Africa offering a wide range of accredited courses including part-time, full-time, and online learning options.",
    
    // Educational offerings
    "offers": {
      "@type": "Offer",
      "category": "Educational Programs",
      "availability": "https://schema.org/InStock",
      "businessFunction": "http://purl.org/goodrelations/v1#ProvideService"
    },

    // Academic calendar
    "academicTerm": [
      {
        "@type": "DefinedTerm",
        "termCode": "Full-Time",
        "name": "Full-Time Studies"
      },
      {
        "@type": "DefinedTerm",
        "termCode": "Part-Time",
        "name": "Part-Time Studies"
      },
      {
        "@type": "DefinedTerm",
        "termCode": "Online",
        "name": "Online Learning"
      }
    ],
  };
};

export const generateArticleSchema = (article, siteUrl = 'https://www.unicollege.co.za') => {
  if (!article) return null;

  const {
    title,
    slug,
    featuredImage,
    _updatedAt, // If available from Sanity
    _createdAt,  // If available from Sanity
  } = article;

  return {
    "@context": "https://schema.org",
    "@type": "Article",
    "@id": `${siteUrl}/${slug.current}#article`,
    "headline": title,
    "name": title,
    "url": `${siteUrl}/${slug.current}`,
    "isPartOf": {
      "@id": "https://www.unicollege.co.za/#website"
    },
    "publisher": {
      "@id": "https://www.unicollege.co.za/#organization"
    },
    "image": featuredImage?.asset?.url ? {
      "@type": "ImageObject",
      "url": featuredImage.asset.url,
      "width": featuredImage.asset.metadata?.dimensions?.width || "1200",
      "height": featuredImage.asset.metadata?.dimensions?.height || "630"
    } : undefined,
    "datePublished": _createdAt || new Date().toISOString(),
    "dateModified": _updatedAt || new Date().toISOString(),
    "inLanguage": "en-ZA",
    "copyrightYear": new Date().getFullYear(),
    "copyrightHolder": {
      "@id": "https://www.unicollege.co.za/#organization"
    },
    "author": {
      "@type": "Organization",
      "@id": "https://www.unicollege.co.za/#organization",
      "name": "UniCollege"
    },
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `${siteUrl}/${slug.current}`
    }
  };
};