import React from 'react'
import urlBuilder from '@sanity/image-url'

const ArticleImage = ({ value }) => {
  const imgSource = urlBuilder({
    projectId: "bhoyv8qz",
    dataset: "production"
  }).image(value).url()

  const imgStyles = value.styles

  const styles = {
    ...(imgStyles?.width && { width: imgStyles.width }),
    ...(imgStyles?.height && { maxHeight: imgStyles.height }),
  }

  if (value.caption) {
    return (
      <figure 
        className={imgStyles?.center ? 'center' : ''}
        style={styles} 
      >
        <img 
          src={imgSource} 
          alt={value.alt || ""}
        />
        <figcaption>{value.caption}</figcaption>
      </figure>
    )
  }

  return (
    <img 
      src={imgSource} 
      alt={value.alt || ""} 
      className={imgStyles?.center ? 'center' : ''} 
      style={styles}
    />
  )
}

export default ArticleImage